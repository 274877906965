import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Helmut from "../components/helmut"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes


  //fix rehydration bugs?
  //https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect( ()=> {
    setHasMounted(true);
  },[]);

  if(!hasMounted)
  {
    return null;
  }


  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Helmut title="All posts" />
        <Bio />
        <p>
          No posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut title="All posts" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    className="articleBody"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      <hr />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark
    (
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {tags: {in: "events"}}} 
    ) 
    {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`
